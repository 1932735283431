.square {
  float: left;
  position: relative;
  width: 20%;
  padding-bottom: 20%; /* = width for a 1:1 aspect ratio */
  margin: 0.4%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

.square-bottom {
  float: left;
  position: relative;
  width: 20%;
  padding-bottom: 20%; /* = width for a 1:1 aspect ratio */
  margin: 0.4%;
  overflow: hidden;
  color: var(--primary-color);
}

.rectangle {
  float: left;
  position: relative;
  width: 20%;
  margin: 0.1%;
  overflow: visible;
}

.rectangle-left {
  float: left;
  position: relative;
  width: 8%;
  padding-bottom: 20%; /* = width for a 1:1 aspect ratio */
  margin-right: 5%;
  margin-top: 0.4%;
  margin-bottom: 0.4%;
  overflow: hidden;
  color: var(--primary-color);
}

.rectangle-left-bottom {
  float: left;
  position: relative;
  width: 8%;
  padding-bottom: 10%; /* = width for a 1:1 aspect ratio */
  margin: 1.66%;
  overflow: hidden;
}

.content {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
}

.table-cell-left {
  display: table-cell;
  font-size: 1.8em;
  text-align: center;
  vertical-align: middle;
  padding-top: 20%;
}

.table-cell {
  padding-top: 10px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.8em;
  text-align: center;
}

.table-cell-prev {
  font-size: 0.7em !important;
  text-align: center;
  color: grey;
}

.dashboard-container {
  background-color: rgba(255, 255, 255, 0.13);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.indicatoren {
  color: var(--primary-color);
  font-size: 1em;
  font-weight: bold;
}

.indicatoren-header {
  color: var(--primary-color);
  font-size: 1.4em;
}

.dashboard-header {
  color: var(--primary-color);
}
