:root {
  --primary-color: #006fb4;
  --secondary-color: #6c757d;
}

.custom-range {
  -webkit-appearance: none;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #006fb4;
  height: 25px; /* Slider handle height */
  width: 25px;
  margin-top: -9px;
}

.custom-range::-moz-range-thumb {
  background: #006fb4;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
}

.custom-range::-ms-thumb {
  background: #006fb4;
}

.custom-range-value {
  -webkit-appearance: none;
}

.checked::-webkit-slider-thumb {
  background: green !important;
}

.checked::-moz-range-thumb {
  background: green !important;
}

.checked::-ms-thumb {
  background: green !important;
}

.range-wrap {
  margin-top: 30px;
  position: relative;
}

.range-value {
  position: absolute;
  top: -120%;
}

.range-value-hidden {
  display: none;
}

.range-value span {
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: green;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}

/*
.range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid red;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
*/

.login-form {
  background-color: rgba(255, 255, 255, 0.13);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

@media (min-width: 250px) {
  .title {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .title {
    font-size: 3rem;
  }
}

th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .caret-4-desc:after {
  content: "\2193";
}

.toolTip {
  color: red;
  position: relative;
}

.toolTip::before {
  content: attr(data-tooltip);

  position: absolute;
  top: -5px;
  right: 105%;
  width: max-content;
  max-width: 200px;

  font-size: 0.8rem;
  padding: 0.4rem;
  border-radius: 0.2rem;

  background: #0d1117;
  border: 2px solid #30363d;
  color: #949aa2;

  opacity: 0;
  visibility: hidden;

  transition: top 0.2s, opacity 0.2s;
}

.toolTipRight {
  color: red;
  position: relative;
}

.toolTipRight::before {
  content: attr(data-tooltip);

  position: absolute;
  top: -5px;
  width: max-content;
  max-width: 195px;

  font-size: 0.8rem;
  padding: 0.4rem;
  border-radius: 0.2rem;

  background: #0d1117;
  border: 2px solid #30363d;
  color: #949aa2;

  opacity: 0;
  visibility: hidden;

  transition: top 0.2s, opacity 0.2s;
}

.toolTip:hover::before {
  top: 50%;
  opacity: 1;
  z-index: 999;
  visibility: visible;
}

.toolTipRight:hover::before {
  top: 50%;
  opacity: 1;
  z-index: 999;
  visibility: visible;
}

.btn {
  border-radius: 0px;
}

.btn-glass {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
  padding: 1em 2em;
  border: 1px dotted transparent;
  letter-spacing: 0.98pt;
  -webkit-transition: background-position 2s cubic-bezier(0, 1, 0, 1),
    border-color 500ms, background-color 500ms;
  transition: background-position 2s cubic-bezier(0, 1, 0, 1),
    border-color 500ms, background-color 500ms;
  position: relative;
  background-attachment: fixed, scroll;
  background-size: 100vw 100vh, cover;
  background-position: center center, 0 0;

  background-image: -webkit-repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 8%,
      rgba(255, 255, 255, 0.075) 10%,
      rgba(255, 255, 255, 0.075) 14%,
      rgba(255, 255, 255, 0.15) 14%,
      rgba(255, 255, 255, 0.15) 15%,
      rgba(255, 255, 255, 0.075) 17%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 36%,
      rgba(255, 255, 255, 0.075) 40%,
      rgba(255, 255, 255, 0.15) 42%,
      rgba(255, 255, 255, 0) 43%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0.075) 60%,
      rgba(255, 255, 255, 0.075) 66%,
      rgba(255, 255, 255, 0.15) 66%,
      rgba(255, 255, 255, 0.075) 70%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0) 100%
    ),
    -webkit-radial-gradient(ellipse farthest-corner, transparent, rgba(
            0,
            0,
            0,
            0.2
          )
          110%);

  background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0) 8%,
      rgba(255, 255, 255, 0.075) 10%,
      rgba(255, 255, 255, 0.075) 14%,
      rgba(255, 255, 255, 0.15) 14%,
      rgba(255, 255, 255, 0.15) 15%,
      rgba(255, 255, 255, 0.075) 17%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 36%,
      rgba(255, 255, 255, 0.075) 40%,
      rgba(255, 255, 255, 0.15) 42%,
      rgba(255, 255, 255, 0) 43%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0.075) 60%,
      rgba(255, 255, 255, 0.075) 66%,
      rgba(255, 255, 255, 0.15) 66%,
      rgba(255, 255, 255, 0.075) 70%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      ellipse farthest-corner,
      transparent,
      rgba(0, 0, 0, 0.2) 110%
    );
}

.btn-glass:hover {
  
  background-position: -100vw 0, 0 0;
}

.btn-glass:active {
  background-position: -75vw 0, 0 0;
  border-style: solid;
}

.nav-light .btn-glass {
  color: #585858;
  background-color: rgba(17, 17, 17, 0);
}

.nav-light .btn-glass:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: #000000;
  background-color: #111111;
}

.nav-light .btn-glass:active {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1em 0.5ex rgba(17, 17, 17, 0.5);
}

.nav-light .btn-glass.btn-primary {
  background-color: rgba(42, 143, 189, 0);
}

.nav-light .btn-glass.btn-primary:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: #1c607e;
  background-color: #2a8fbd;
}

.nav-light .btn-glass.btn-primary:active {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1em 0.5ex rgba(42, 143, 189, 0.5);
}

.nav-light .btn-glass.btn-success {
  background-color: rgba(127, 175, 27, 0);
}

.nav-light .btn-glass.btn-success:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: #4f6d11;
  background-color: #3cb22e;
}

.nav-light .btn-glass.btn-success:active {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1em 0.5ex rgba(127, 175, 27, 0.5);
}

.nav-light .btn-glass.btn-warning {
  color: #fccd69;
  background-color: rgba(251, 184, 41, 0);
}

.nav-light .btn-glass.btn-warning:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: #d49104;
  background-color: #fbb829;
}

.nav-light .btn-glass.btn-warning:active {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1em 0.5ex rgba(251, 184, 41, 0.5);
}

.nav-light .btn-glass.btn-danger {
  color: #aa180b;
  background-color: rgba(240, 35, 17, 0);
}

.nav-light .btn-glass.btn-danger:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: #aa180b;
  background-color: #f02311;
}

.nav-light .btn-glass.btn-danger:active {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1em 0.5ex rgba(240, 35, 17, 0.5);
}

.nav-light .btn-glass.btn-info {
  background-color: rgba(108, 223, 234, 0);
}

.nav-light .btn-glass.btn-info:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: #29d0e0;
  background-color: #6cdfea;
}

.nav-light .btn-glass.btn-info:active {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1em 0.5ex rgba(108, 223, 234, 0.5);
}

.noHover {
  pointer-events: none;
}

.textDanger {
  color: red;
}

.header {
  color: #fff;
  background-color: #006fb4;
}

.vertical-center {
  min-height: 80%; /* Fallback for browsers do NOT support vh unit */
  min-height: 80vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.peildatum-btn {
  border: none !important;
}

.peildatum-btn:hover {
  text-decoration: none;
  background-color: #28a745 !important;
  border: none !important;
  box-shadow: none !important;
  cursor: default !important;
}

.modal-info-tip {
  cursor: help;
}

.modal-dialog {
  max-width: 1200px !important;
}