.btn-new-outline-primary:hover {
    background-color: #006fb4;
    color: #fff
}

.btn-new-outline-primary {
    background-color: transparent;
    border-color: #006fb4;
    color: #006fb4
}

.btn-new-primary:hover {
    background-color: #fff;
    color: #006fb4;
    border-color: #006fb4;
}

.btn-new-primary {
    background-color: #006fb4;
    border-color: #fff;
    color: #fff
}